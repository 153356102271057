<script setup>
import { ref } from "vue";
import MainLayout from "@/layouts/MainLayout.vue";

const commands = ref([""]);
const output = ref([]);
const user = ref(null);

const handleCommand = async (index) => {
  const command = commands.value[index].trim();
  if (command) {
    output.value.push(`black@black:~$ ${command}`);

    if (command === "help") {
      output.value.push("Available commands:");
      output.value.push("auth login        -- <email> <password> - Log in");
      output.value.push(
        "auth register     -- <email> <password> - Register and log in"
      );
      output.value.push(
        "add project       -- <project_name> - Add a new project"
      );
      output.value.push(
        "update project    -- <project_id> <project_name> - Update an existing project"
      );
      output.value.push("leave comment     -- <comment> - Leave a comment");
    } else if (command.startsWith("black auth login")) {
      const [_, email, password] = command.split(" ");
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        user.value = userCredential.user;
        output.value.push("Logged in successfully.");
      } catch (error) {
        output.value.push(`Login failed: ${error.message}`);
      }
    } else if (command.startsWith("black auth register")) {
      const [_, email, password] = command.split(" ");
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        user.value = userCredential.user;
        output.value.push("Registered and logged in successfully.");
      } catch (error) {
        output.value.push(`Registration failed: ${error.message}`);
      }
    } else if (command.startsWith("black add project")) {
      if (user.value) {
        const projectName = command.replace("black add project", "").trim();
        try {
          await addDoc(collection(db, "projects"), {
            name: projectName,
            userId: user.value.uid,
          });
          output.value.push("Project added successfully.");
        } catch (error) {
          output.value.push(`Error adding project: ${error.message}`);
        }
      } else {
        output.value.push("Error: Please log in first.");
      }
    } else if (command.startsWith("black update project")) {
      if (user.value) {
        const [_, projectId, projectName] = command.split(" ");
        try {
          const projectRef = doc(db, "projects", projectId);
          await updateDoc(projectRef, {
            name: projectName,
          });
          output.value.push("Project updated successfully.");
        } catch (error) {
          output.value.push(`Error updating project: ${error.message}`);
        }
      } else {
        output.value.push("Error: Please log in first.");
      }
    } else if (command.startsWith("black leave comment")) {
      const comment = command.replace("black leave comment", "").trim();
      if (comment) {
        output.value.push(`Comment added: "${comment}"`);
      } else {
        output.value.push("Error: Comment cannot be empty.");
      }
    } else {
      output.value.push(`Error: Command not found - ${command}`);
    }

    commands.value.push("");
  }
};
</script>
<template>
  <MainLayout>
    <div class="text-center mt-[-3.3em] ml-0">
      <div class="relative top-8 w-[80rem]">
        <ul class="bg-[#776849] hidden lg:flex">
          <li
            class="font-medium tracking-wide text-[#0e0d0d] font-mono transition-colors duration-200 hover:text-teal-accent-400"
          >
            <NuxtLink
              to="#"
              class="px-5"
              active-class="active-link"
              exact-active-class="exact-active-link"
            >
              black~:Terminal
            </NuxtLink>
          </li>
        </ul>
        <div v-for="(command, index) in commands" :key="index" class="relative">
          <span class="text-[#776849] justify-start flex items-start px-2 ml-[-0.5em] mt-2">
            black@black:~$
            <input
              class="absolute w-full px-4 bg-transparent text-[#776849] outline-none ml-[6em]"
              type="text"
              v-model="commands[index]"
              @keydown.enter="handleCommand(index)"
              :disabled="index !== commands.length - 1"
            />
          </span>
        </div>
        <div v-if="output.length" class="text-left mt-4 ml-3">
          <p
            v-for="(line, index) in output"
            :key="index"
            class="text-[#776849]"
          >
            {{ line }}
          </p>
        </div>
      </div>
      <slot />
    </div>
  </MainLayout>
</template>

<style scoped>
.font-mono {
  font-family: "Courier New", Courier, monospace;
}

.active-link {
  background-color: #0e0d0d;
  color: #776849;
  padding: 4px;
}

.exact-active-link {
  background-color: #0e0d0d;
  color: #776849;
}

@media (min-width: 640px) {
  .mt-10 {
    margin-top: 10em;
  }
}
</style>
